import fetch from 'isomorphic-fetch'
import { checkHttpStatus, handleNetworkError, parseJSON } from '../../utils'
import jwtDecode from 'jwt-decode';
import { userSerializer } from './serializers'
import TokenStorage from '../../storages/token-storage'

export const REQUEST_USERS = 'REQUEST_USERS'
export const RECEIVE_USERS = 'RECEIVE_USERS'
export const RECEIVE_USERS_ERROR = 'RECEIVE_USERS_ERROR'
export const REQUEST_CREATE_USER = 'REQUEST_CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'


export const requestUsers = () => {
    return {
        type: REQUEST_USERS,
    };
};

export const receiveUsers = (response) => {
    return {
        type: RECEIVE_USERS,
        data: response,
    };
};

export const requestCreateUser = () => {
    return {
        type: REQUEST_CREATE_USER,
    };
};

export const receiveUsersError = () => {
    return {
        type: RECEIVE_USERS_ERROR,
    };
};

export const createUserSuccess = (response) => {
    return {
        type: CREATE_USER_SUCCESS,
        data: response,
    };
};

export const createUserError = () => {
    return {
        type: CREATE_USER_ERROR,
    };
};

export const fetchUsers = () => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");
        
        dispatch(requestUsers());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/users/`, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveUsers(response));
            } catch (e) {
                dispatch(receiveUsersError(response))
            }
        })
        .catch(handleNetworkError);
    };
};

export const createUser = (user, updateUser=false) => {
    return dispatch => {
        let authToken = TokenStorage.getItem("token");
        
        dispatch(requestCreateUser());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.CSRF_TOKEN,
        }
        if (authToken) {
            headers.Authorization = `jwt ${authToken}`;
        }

        return fetch(`/api/v1/users/${user.id ? `${user.id}/` : ''}`, {
            method: updateUser ? 'PUT' : 'POST',
            headers: headers,
            body: JSON.stringify(userSerializer(user)),
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(createUserSuccess(response));
            } catch (e) {
                dispatch(createUserError(response))
            }
        })
        .catch(handleNetworkError);
    };
};

export const updateUser = (user) => {
    return createUser({...user}, true)
};